.Input {
	//text-align: center;

	&-logo {
		height: 30vmin;
		margin: 0 0 20px;
		pointer-events: none;
	}

	&-link {
		color: #008cc0;
	}

	//border: 1px solid black;
	//width: 500px;
	//height: 700px; //900px;
	//padding: 20px;
}

.h-500 {
	height: 500px;
}

.inProgress {
	border: 1px solid red;
	//margin-top: 15px;
}

.chkClass {
	margin-top: 5px;
}

.statusUpdate {
	margin-top: 15px;
	border: 1px dashed black;
	height: 150x;
}

.txtInput {
	width: 40px;
}

.Header {
	font-weight: bold;
	font-size: larger;
}

.halfSection {
	width: 50%;
}

.section {
	margin-top: 15px;
	margin-left: 5px;
	margin-right: 5px;
}

.sectionButton {
	margin-top: 15px;
	margin-left: 50%;
	display: inline-block;
}

.margRight {
	margin-right: 15px;
}

.centered {
	text-align: center;
	line-height: 150px;
}

.btnClass {
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
}

.btnStyle {
	color: #4766ff;
	border: 2px #4766ff solid;
	border-radius: 6px;
	padding: 8px;
	cursor: pointer;
}

.btnStyle:hover {
	color: white;
	background-color: #4766ff;
	border: 2px #4766ff solid;
	border-radius: 6px;
	padding: 8px;
	cursor: pointer;
}

input[type='text'] {
	padding: 4px;
	border-radius: 4px;
	border: 2px rgb(204, 204, 204) solid;
}

input[type='text']:focus {
	outline: none;
	border: 2px #4766ff solid !important;
}

.select__input > input[type='text']:focus {
	outline: none !important;
	border: none !important;
}

::selection {
	background: #4766ff; /* WebKit/Blink Browsers */
	color: white;
}

::-moz-selection {
	background: #4766ff; /* Gecko Browsers */
	color: white;
}

@media (prefers-reduced-motion: no-preference) {
	.Home-logo {
		animation: App-logo-float infinite 3s ease-in-out;
	}
}

@keyframes App-logo-float {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0px);
	}
}
