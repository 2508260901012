.cal {
	display: inline-block;
	margin-left: 5px;
	margin-right: 5px;
}

.dash {
	margin-left: 10px;
	margin-right: 10px;
}

// .react-datepicker-wrapper,
// .react-datepicker__input-container,
.react-datepicker__input-container input {
	padding: 4px;
	border-radius: 4px;
	border: 1px rgb(204, 204, 204) solid;
}

.react-datepicker__day--selected {
	background-color: rgb(71, 102, 255);
}

.react-datepicker__day--selected:hover {
	color: black;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
	background-color: rgba(71, 102, 255, 0.2);
}
