.App {
	//display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	font-size: 14px;

	&-loading {
		margin: auto;
		text-align: center;
	}
}
