.btnStyle {
	color: #4766ff;
	border: 2px #4766ff solid;
	border-radius: 6px;
	padding: 8px;
	cursor: pointer;
}

.btnStyle:hover {
	color: white;
	background-color: #4766ff;
	border: 2px #4766ff solid;
	border-radius: 6px;
	padding: 8px;
	cursor: pointer;
}

.btnStyleGray {
	color: gray;
	border: 2px gray solid;
	border-radius: 6px;
	padding: 8px;
	cursor: default;
}
