@tailwind base;
@tailwind components;
@tailwind utilities;

// body {
// 	margin: 0;
// 	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
// 		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
// 	-webkit-font-smoothing: antialiased;
// 	-moz-osx-font-smoothing: grayscale;
// 	// background-color: #212529;
// 	// color: #fff;
// 	background-color: white;
// 	color: black;
// }

// code {
// 	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
// }
